<template>
  <div class="bg-white w-full h-screen flex flex-col justify-center items-center">
    <span v-if="status !== 410 && status !== 411" class="status text-9xl">{{ status }}</span>
    <div class="text-xl">{{ description }}</div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps({ status: Number })

// const title = computed(() => {
//   return {
//     503: 'Không có dịch vụ!',
//     404: 'Không Tìm Thấy Trang!',
//     403: 'Cấm',
//   }[props.status]
// })

const description = computed(() => {
  return {
    503: 'Xin lỗi, chúng tôi đang thực hiện một số bảo trì. Làm ơn hãy kiểm tra lại sớm.',
    404: 'Xin lỗi, trang bạn đang tìm kiếm không thể tìm thấy.',
    403: 'Xin lỗi, bạn bị cấm truy cập trang này.',
    410: 'Đã có lỗi xảy ra khi đăng ký với facebook. Vui lòng thử với tài khoản khác.',
    411: 'Đã có lỗi xảy ra khi đăng ký với google. Vui lòng thử với tài khoản khác.',
  }[props.status]
})
</script>
